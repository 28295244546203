import { getAvailability, getNextFreeSlot } from '../../api/room-availability.js';
import { rfc3339, rfc3339Date, rfc3339DateLocalTz, rfc3339TimeWithRoundedMinutesLocalTz } from '../../api/rfc-3339.js';
import { siteLang } from '../../helpers/translations.js';

class MrTimeRange extends HTMLElement {
	static get observedAttributes() {
		return [
			'room-id',
			'subroom-id',
		];
	}

	constructor() {
		super();

		this._valueChangedTimeout = 0;

		this._stateHandler = ( e ) => {
			if ( !e || !e.detail ) {
				return;
			}

			if ( e.detail.step_room && e.detail.step_room.rooms ) {
				this.selected_room_id = '';
				this.selected_subroom_id = '';

				e.detail.step_room.rooms.forEach( ( room ) => {
					if ( !room.selected ) {
						return;
					}

					this.roomID = room.room_id;

					if ( room.sub_rooms && room.sub_rooms.length ) {
						room.sub_rooms.forEach( ( sub_room ) => {
							if ( !sub_room.selected ) {
								return;
							}

							this.subRoomID = sub_room.subroom_id;
						} );
					}
				} );
			}
		};

		this._changeHandler = ( e ) => {
			if ( !e ) {
				return;
			}

			if ( e.target.classList.contains( 'js-from-date' ) || e.target.classList.contains( 'js-until-date' ) ) {
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			if ( e.target.classList.contains( 'js-date-field' ) ) {
				this._setHourSlots();
			}

			this._validateAndSetFormValue();
		};

		this._clickHandler = ( e ) => {
			if ( !e ) {
				return;
			}

			if ( !e.target.closest( 'button[go-to-next-free-slot]' ) ) {
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			if ( !this.roomID ) {
				return;
			}

			const dateValues = this.getDateValues();
			getNextFreeSlot(
				this.roomID,
				this.subRoomID,
				dateValues.fromTime ?? null
			).then( ( x ) => {
				const dateField = this.querySelector( '.js-date-field' );
				const timeFromField = this.querySelector( '.js-hour-field-from' );
				const timeUntilField = this.querySelector( '.js-hour-field-until' );
				if ( !dateField || !timeFromField || !timeUntilField ) {
					console.warn( 'invalid view' );

					return;
				}

				const start = new Date( x.start );
				const end = new Date( x.start );
				end.setHours( end.getHours() + 3 );

				dateField.value = rfc3339DateLocalTz( start );

				this._setHourSlots();

				timeFromField.value = rfc3339TimeWithRoundedMinutesLocalTz( start );
				timeUntilField.value = rfc3339TimeWithRoundedMinutesLocalTz( end );

				this._validateAndSetFormValue();

				this.setAttribute( 'data-values-changed-by-script', '' );
				clearTimeout( this._valueChangedTimeout );
				this._valueChangedTimeout = setTimeout( () => {
					this.removeAttribute( 'data-values-changed-by-script' );
				}, 1250 );

			} ).catch( ( err ) => {
				// TODO : err handling
				console.warn( err );
			} );

		};
	}

	_setHourSlots() {
		const dateValue = this.querySelector( '.js-date-field' ).value;
		const hourFieldOptions = this.querySelectorAll( '.js-hour-field option' );

		for ( let i = 0; i < hourFieldOptions.length; i++ ) {
			const option = hourFieldOptions[i];
			option.value = `${dateValue}T${option.dataset.hourSlot}`;

			if (
				option.dataset.hourSlot.startsWith( '00:' ) ||
				option.dataset.hourSlot.startsWith( '01:' ) ||
				option.dataset.hourSlot.startsWith( '02:' )
			) {
				const slotValue = new Date( `${dateValue}T${option.dataset.hourSlot}` );
				slotValue.setDate( slotValue.getDate() + 1 );
				option.value = rfc3339( slotValue );
			}
		}
	}

	async _validateAndSetFormValue() {
		const dateValues = this.getDateValues();

		if ( dateValues.state === 'invalid view' ) {
			console.warn( 'invalid view' );

			return;
		}

		const {
			fromDate,
			untilDate,
		} = dateValues;

		// Helper function to set a clean state
		const setEmptyState = () => {
			const listItemMessageArea = this.querySelector( '.js-time-slot-message' );
			if ( !listItemMessageArea ) {
				console.warn( 'no message view for errors' );

				return;
			}

			this.removeAttribute( 'data-has-error' );
			listItemMessageArea.innerHTML = '';
			listItemMessageArea.removeAttribute( 'data-has-error' );

			fromDate.setCustomValidity( '' );
			fromDate.checkValidity();
			untilDate.setCustomValidity( '' );
			untilDate.checkValidity();
		};

		if ( !this.roomID ) {
			setEmptyState();

			return;
		}

		if ( dateValues.state === 'no values' ) {
			setEmptyState();

			return;
		}

		if ( dateValues.state === 'invalid values' ) {
			const listItemMessageArea = this.querySelector( '.js-time-slot-message' );
			if ( !listItemMessageArea ) {
				console.warn( 'no message view for errors' );

				return;
			}

			if ( 'en' === siteLang() ) {
				listItemMessageArea.innerHTML = 'Please enter a future date in YYYY-MM-DD format.';
			} else {
				listItemMessageArea.innerHTML = 'Vul een toekomstige datum in met formaat YYYY-MM-DD.';
			}

			this.setAttribute( 'data-has-error', '' );
			listItemMessageArea.setAttribute( 'data-has-error', '' );

			return;
		}

		const {
			fromTime,
			untilTime,
		} = dateValues;

		const availabilityCheckKey = `${this.roomID}--${this.subRoomID}--${fromTime}--${untilTime}`;
		if ( availabilityCheckKey === this.availabilityCheckKey ) {
			return;
		}

		this.availabilityCheckKey = availabilityCheckKey;

		try {
			const availability = await getAvailability(
				this.roomID,
				this.subRoomID,
				fromTime,
				untilTime
			);

			if ( this.availabilityCheckKey !== availabilityCheckKey ) {
				// not the latest check call
				return;
			}

			if ( ( availability.has_errors || availability.has_warnings ) && availability.messages && availability.messages.length ) {
				const messages = [];

				for ( let j = 0; j < availability.messages.length; j++ ) {
					if ( availability.messages[j].message ) {
						let message = availability.messages[j].message;
						message = message.replaceAll( /(\d)\s+/g, '$1&nbsp;' );
						messages.push( message );
					}
				}

				const listItemMessageArea = this.querySelector( '.js-time-slot-message' );
				if ( !listItemMessageArea ) {
					console.warn( 'no message view for errors' );

					return;
				}

				this.setAttribute( 'data-has-error', '' );
				listItemMessageArea.innerHTML = messages.join( '<br>' );
				listItemMessageArea.setAttribute( 'data-has-error', '' );

				fromDate.setCustomValidity( messages[0] );
				fromDate.checkValidity();
				untilDate.setCustomValidity( messages[0] );
				untilDate.checkValidity();

				return;
			}

			const listItemMessageArea = this.querySelector( '.js-time-slot-message' );
			if ( !listItemMessageArea ) {
				console.warn( 'no message view for errors' );

				return;
			}

			if ( 'en' === siteLang() ) {
				listItemMessageArea.innerHTML = 'Available!';
			} else {
				listItemMessageArea.innerHTML = 'Beschikbaar!';
			}

			this.removeAttribute( 'data-has-error' );
			listItemMessageArea.removeAttribute( 'data-has-error' );

			fromDate.setCustomValidity( '' );
			fromDate.checkValidity();
			untilDate.setCustomValidity( '' );
			untilDate.checkValidity();
		} catch ( e ) {
			// TODO : err handling
			console.warn( e );
		}
	}

	getDateValues() {
		// Gather Elements
		const fromDate = this.querySelector( '.js-hour-field-from' );
		const untilDate = this.querySelector( '.js-hour-field-until' );
		if ( !fromDate || !untilDate ) {
			return {
				state: 'invalid view',
			};
		}

		const [
			fromDateValue,
			untilDateValue,
		] = fromDate.value <= untilDate.value ? [ // eslint-disable-line no-ternary
			fromDate.value,
			untilDate.value,
		] : [
			untilDate.value,
			fromDate.value,
		];

		if ( !fromDateValue || !untilDateValue ) {
			return {
				state: 'no values',
				fromDate: fromDate,
				untilDate: untilDate,
			};
		}

		const fromTime = new Date( fromDateValue );
		const untilTime = new Date( untilDateValue );
		if ( !this.isValidDate( fromTime ) || !this.isValidDate( untilTime ) ) {
			return {
				state: 'invalid values',
				fromDate: fromDate,
				fromDateValue: fromDateValue,
				untilDate: untilDate,
				untilDateValue: untilDateValue,
			};
		}

		return {
			state: 'valid',
			fromDate: fromDate,
			fromDateValue: fromDateValue,
			fromTime: fromTime,
			untilDate: untilDate,
			untilDateValue: untilDateValue,
			untilTime: untilTime,
		};
	}

	isValidDate( date ) {
		const isValidDateInstance = date instanceof Date && !isNaN( date );
		const dateIsFuture = date > this.endOfDay();

		return isValidDateInstance && dateIsFuture;
	}

	endOfDay() {
		const endOfDay = new Date();
		endOfDay.setDate( endOfDay.getDate() + 1 );
		endOfDay.setHours( 0 );
		endOfDay.setMinutes( -1 );
		endOfDay.setSeconds( 0 );

		return endOfDay;
	}

	get roomID() {
		return this.getAttribute( 'room-id' ) || '';
	}

	// Implement disabled state
	set roomID( value ) {
		if ( null === value ) {
			this.removeAttribute( 'room-id' );
		} else {
			this.setAttribute( 'room-id', value );
		}
	}

	get subRoomID() {
		return this.getAttribute( 'subroom-id' );
	}

	// Implement disabled state
	set subRoomID( value ) {
		if ( null === value ) {
			this.removeAttribute( 'subroom-id' );
		} else {
			this.setAttribute( 'subroom-id', value );
		}
	}

	attributeChangedCallback( attrName, oldVal, newVal ) {
		if ( !this.inDOM() ) {
			return;
		}

		if ( 'room-id' !== attrName && 'subroom-id' !== attrName ) {
			return;
		}

		if ( oldVal === newVal ) {
			return;
		}

		// Validate on the next event loop cycle
		requestAnimationFrame( () => {
			this._validateAndSetFormValue();
		} );
	}

	// Override setAttribute to handle JSON values
	setAttribute( attr, value ) {
		if ( 'room-id' === attr ) {
			const val = `${value}`;
			if ( val === super.getAttribute( 'room-id' ) ) {
				return;
			}

			super.setAttribute( attr, val );

			return;
		}

		if ( 'subroom-id' === attr ) {
			const val = `${value}`;
			if ( val === super.getAttribute( 'subroom-id' ) ) {
				return;
			}

			super.setAttribute( attr, val );

			return;
		}

		super.setAttribute( attr, value );
	}

	// Life cycle
	connectedCallback() {
		requestAnimationFrame( () => {
			this._addEventListeners();

			{
				const dateField = this.querySelector( '.js-date-field' );
				if ( !dateField.value || ( new Date( dateField.value ) ).valueOf() < this.tomorrow().valueOf() ) {
					dateField.value = rfc3339Date( this.tomorrow() );
				}
			}

			this._setHourSlots();
			this._validateAndSetFormValue();
		} );
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		this.addEventListener( 'change', this._changeHandler );
		this.addEventListener( 'click', this._clickHandler );
		window.addEventListener( 'rent-space:state-change', this._stateHandler );
	}

	_removeEventListeners() {
		this.removeEventListener( 'change', this._changeHandler );
		this.removeEventListener( 'click', this._clickHandler );
		window.removeEventListener( 'rent-space:state-change', this._stateHandler );
	}

	inDOM() {
		if ( !this.parentNode ) {
			return false;
		}

		if ( !document.body.contains( this ) ) {
			return false;
		}

		return true;
	}

	tomorrow() {
		const nowPlus1 = new Date();
		nowPlus1.setDate( nowPlus1.getDate() + 1 );

		return nowPlus1;
	}
}

customElements.define( 'mr-time-range', MrTimeRange );
