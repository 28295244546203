export const rfc3339 = ( d ) => {
	return d.getUTCFullYear() + '-' +
	pad( d.getUTCMonth() + 1 ) + '-' +
	pad( d.getUTCDate() ) + 'T' +
	pad( d.getUTCHours() ) + ':' +
	pad( d.getUTCMinutes() ) + ':' +
	pad( d.getUTCSeconds() ) + 'Z';
};

export const rfc3339Date = ( d ) => {
	return d.getUTCFullYear() + '-' +
	pad( d.getUTCMonth() + 1 ) + '-' +
	pad( d.getUTCDate() );
};

const pad = ( n ) => {
	return ( '0' + n ).slice( -2 );
};

export const rfc3339DateLocalTz = ( d ) => {
	return d.getFullYear() + '-' +
	pad( d.getMonth() + 1 ) + '-' +
	pad( d.getDate() );
};

export const rfc3339TimeWithRoundedMinutesLocalTz = ( d ) => {
	return d.getFullYear() + '-' +
	pad( d.getMonth() + 1 ) + '-' +
	pad( d.getDate() ) + 'T' +
	pad( d.getHours() ) + ':' +
	pad( ( Math.round( d.getMinutes() / 15 ) * 15 ) );
};
